import { getOrders } from '../common/api/orders';
import { getOrdersMeta } from '../common/api/orders';
import { Order } from '../common/types';
import { getDuration } from '../common/orders';
import { RentalStatusIcon } from '../common/orders';
import { getDurationString } from '../common/filtering/filter';

import { DefinedQueryTableProps, QueryTable } from './QueryTable';
import { DeviceLink } from './DeviceLink';
import { VenueLink } from './VenueLink';

export type OrderTableProps = DefinedQueryTableProps<Order> & {
  description?: string;
};

export function OrderTable(props: OrderTableProps) {
  const getItems = props.getItems ?? getOrders;
  const getMeta = props.getMeta ?? getOrdersMeta;

  return (
    <QueryTable
      {...props}
      columnDefinitions={[
        {
          id: 'rent_time',
          sortingField: 'rent_time',
          header: 'Rented',
          cell: (e: Order) => new Date(e.rent_time + 'Z').toLocaleString(),
        },
        // {
        //   id: 'payment_status',
        //   header: 'Payment Status',
        //   cell: (e: Order) => <PaymentStatusIcon status={e.payment_status} />,
        // },
        {
          id: 'rent_status',
          sortingField: 'rent_status',
          header: 'Status',
          cell: (e: Order) => <RentalStatusIcon status={e.rent_status} />,
        },
        {
          id: 'device_id',
          header: 'Device',
          cell: (e: Order) => <DeviceLink device={e.device} />,
        },
        {
          id: 'venue_name',
          header: 'Venue',
          cell: (e: Order) => <VenueLink maxLength={20} venue={e.from_venue} />,
        },
        {
          id: 'settlement_amount',
          sortingField: 'settlement_amount',
          header: 'Revenue',
          cell: (e: Order) =>
            `$${parseFloat(e.settlement_amount).toFixed(2)} ${e.from_venue.currency ?? 'USD'}`,
        },
        {
          id: 'duration',
          header: 'Duration',
          cell: (e: Order) => {
            const diff = getDuration(e);

            return getDurationString(diff);
          },
        },
      ]}
      description={props.description ?? 'Zappy rentals within the specified time range'}
      endpointName="orders"
      getItems={getItems}
      getMeta={getMeta}
      resourceName="Rentals"
      past
    />
  );
}
