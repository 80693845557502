import {
  Box,
  Header,
  SpaceBetween,
  Pagination,
  CollectionPreferences,
  Table,
  Button,
  DateRangePickerProps,
  PropertyFilterProps,
  TableProps,
} from '@cloudscape-design/components';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { useCollectionPreferences } from '../common/preferences/collections';
import { PropertyDateFilter } from '../common/filtering/filtering';
import { PaginatedResponse } from '../common/api/query';
import { QueryMeta } from '../common/api/meta';

import { ResourceError } from './Loading';

export type DefinedQueryTableProps<T> = {
  now: Date;
  setNow: (now: Date) => void;
  query: PropertyFilterQuery;
  setQuery?: (query: PropertyFilterQuery) => void;
  range?: DateRangePickerProps.Value | null;
  setRange?: (range: DateRangePickerProps.Value | null) => void;
  sortingField?: string;
  setSortingField?: (field: string | undefined) => void;
  sortDirection?: 'asc' | 'desc';
  setSortDirection?: (direction: 'asc' | 'desc') => void;
  past?: boolean;
  getItems?: (
    query: PropertyFilterQuery,
    page: number,
    pageSize: number,
    range?: DateRangePickerProps.Value | null,
    sortingField?: string,
    sortDirection?: 'asc' | 'desc',
    now?: Date,
  ) => Promise<PaginatedResponse<T>>;
  getMeta?: () => Promise<QueryMeta>;
  hideActions?: boolean;
  hideFilter?: boolean;
  hideDateRange?: boolean;
  hidePagination?: boolean;
  hiddenColumns?: string[];
  variant?: TableProps.Variant;
  additionalActions?: React.ReactNode;
};

export type QueryTableProps<T> = DefinedQueryTableProps<T> & {
  resourceName: string;
  endpointName: string;
  description?: string;
  columnDefinitions: ReadonlyArray<TableProps.ColumnDefinition<T>>;
  getItems: (
    query: PropertyFilterQuery,
    page: number,
    pageSize: number,
    range?: DateRangePickerProps.Value | null,
    sortingField?: string,
    sortDirection?: 'asc' | 'desc',
    now?: Date,
  ) => Promise<PaginatedResponse<T>>;
  getMeta: () => Promise<QueryMeta>;
};

export function QueryTable<T>(props: QueryTableProps<T>) {
  const { resourceName, endpointName, getItems, getMeta } = props;

  // relative times are in the past vs future
  const past = props.past ?? true;

  const { preferences, setPreferences, PAGE_SIZE_OPTIONS, pageSize } =
    useCollectionPreferences(resourceName);

  const { query, setQuery, range, setRange } = props;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const { now, setNow, sortingField, setSortingField, sortDirection, setSortDirection } = props;

  const metaQuery = useQuery({
    queryKey: [endpointName, 'meta', now],
    queryFn: getMeta,
    staleTime: 3_600_000, // 1 hour
  });

  const { options, properties } = useMemo(() => {
    if (metaQuery.data) {
      return {
        options: metaQuery.data.filteringOptions,
        properties: metaQuery.data.filteringProperties,
      };
    }

    return {
      options: [],
      properties: [],
    };
  }, [metaQuery.data]);

  const refresh = () => {
    setNow(new Date());
  };

  // wait for the meta query to finish before proceeding
  const { isPending, isFetching, error, data } = useQuery({
    queryKey: [endpointName, query, range, pageSize, sortingField, sortDirection, page, now],
    enabled: metaQuery.isSuccess,
    queryFn: async () =>
      await getItems(query, page, pageSize, range, sortingField, sortDirection, now),
    staleTime: 3_600_000, // 60 minutes
  });

  const loading =
    (isPending || isFetching || metaQuery.isPending || metaQuery.isFetching) &&
    !error &&
    !metaQuery.error;

  useEffect(() => {
    if (data && data.pages !== pageCount) {
      setPageCount(data.pages);
    }
  }, [data]);

  const pagination = (
    <Pagination
      currentPageIndex={page}
      disabled={loading}
      pagesCount={pageCount}
      onChange={({ detail }) => setPage(detail.currentPageIndex)}
    />
  );

  return (
    <Table
      columnDefinitions={props.columnDefinitions.filter(
        (c) => !c.id || !props.hiddenColumns?.includes(c.id),
      )}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error || metaQuery.error ? (
            <ResourceError
              error={error ?? metaQuery.error ?? new Error('Unknown error')}
              refetch={refresh}
              resourceName={resourceName}
            />
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()} found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      filter={
        props.hideFilter ? undefined : (
          <PropertyDateFilter
            filteringProperties={properties}
            hideDateRange={props.hideDateRange}
            loading={loading}
            past={past}
            propertyFilteringOptions={options}
            query={query}
            range={range ?? null}
            setQuery={(q) => {
              setQuery?.(q);
              // reset page when filtering
              setPage(1);
            }}
            setRange={(r) => {
              setRange?.(r);
              // reset page when filtering
              setPage(1);
            }}
          />
        )
      }
      header={
        <Header
          actions={
            props.hideActions ? undefined : (
              <SpaceBetween direction="horizontal" size="s">
                {props.additionalActions}
                <Button disabled={loading} iconName="refresh" variant="normal" onClick={refresh} />
              </SpaceBetween>
            )
          }
          counter={
            data?.total && metaQuery.data?.total
              ? `(${data.total.toLocaleString()}/${metaQuery.data.total.toLocaleString()})`
              : undefined
          }
          description={props.description}
          variant={
            !props.variant
              ? 'awsui-h1-sticky'
              : props.variant !== 'full-page'
              ? 'h2'
              : 'awsui-h1-sticky'
          }
        >
          {resourceName}
        </Header>
      }
      items={data?.items ?? []}
      loading={loading}
      loadingText={`Loading ${resourceName}...`}
      pagination={props.hidePagination ? undefined : pagination}
      preferences={
        <CollectionPreferences
          disabled={loading}
          pageSizePreference={{
            options: PAGE_SIZE_OPTIONS,
          }}
          preferences={preferences}
          onConfirm={(e) => setPreferences(e.detail)}
        />
      }
      sortingColumn={{
        sortingField,
      }}
      sortingDescending={sortDirection === 'desc'}
      stickyHeader={!props.variant || props.variant === 'full-page'}
      variant={props.variant ?? 'full-page'}
      onSortingChange={({ detail }) => {
        setSortingField?.(detail.sortingColumn.sortingField);
        setSortDirection?.(detail.isDescending ? 'desc' : 'asc');
      }}
    />
  );
}
