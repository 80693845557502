import { useMemo } from 'react';

import { PercentStatus } from '../common/percent-status';
import { VenueWithDevices } from '../common/types';

export type VenueOnlineStatusProps = {
  venue: VenueWithDevices;
};

export function VenueOnlineStatus(props: VenueOnlineStatusProps) {
  const { venue } = props;
  const online = useMemo(() => {
    let online: number | undefined = venue.devices.filter(
      (device) => device.status === 'Online',
    ).length;
    const unknown = venue.devices.filter((device) => device.status === 'Unknown').length;

    if (unknown > 0) {
      online = undefined;
    }

    return online;
  }, [venue]);
  // const total_slots = venue.devices.map((device) => device.slots).reduce((a, b) => a + b, 0);
  // const empty_slots = venue.devices
  //   .map((device) => device.active_slots)
  //   .reduce((a, b) => a + b, 0);
  // let full_slots: number | undefined = total_slots - empty_slots;

  // if (unknown > 0) {
  //   online = undefined;
  //   full_slots = undefined;
  // }
  return <PercentStatus total={venue.devices.length} value={online} />;
}
