import { PropertyFilterOption } from '@cloudscape-design/collection-hooks';
import { PropertyFilterProps } from '@cloudscape-design/components';

import axiosInstance from './axiosInstance';

export type QueryMeta = {
  total: number;
  filteringProperties: PropertyFilterProps.FilteringProperty[];
  filteringOptions: PropertyFilterOption[];
};

export async function getMetaQueryResource(route: string) {
  const response = await axiosInstance.get(route);

  return response.data as QueryMeta;
}
