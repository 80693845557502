import { Header, Grid, Container, Box, ColumnLayout, SpaceBetween, Button } from '@cloudscape-design/components';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { DeviceWithVenue } from '../../common/types';
import { DeviceStatusIcon } from '../../common/devices';
import { VenueLink } from '../../components/VenueLink';
import { PercentStatus } from '../../common/percent-status';
import { getDevice, getDeviceOrders, getDeviceOrdersMeta } from '../../common/api/devices';
import {
  useLocalFilterState,
  useLocalPageState,
  useLocalRangeState,
  useLocalSortState,
} from '../../common/filtering/filter';
import { ViewLayout } from '../../components/ViewLayout';
import { LoadingOrError } from '../../common/loading';
import { OrderTable } from '../../components/OrderTable';

import { DeviceHeartbeatGraph } from './heartbeat-graph';
import { PropertyDateFilter } from '../../common/filtering/filtering';
import { getOrderReport, getOrdersMeta } from '../../common/api/orders';
import { OrdersReport } from '../../components/OrdersReport';
import { ZappyHeader } from '../../common/header';

export type DeviceImageProps = {
  device: DeviceWithVenue;
};

export function DeviceImage(props: DeviceImageProps) {
  const { device } = props;

  // TODO get zappy promo image for each slotted device
  let logo = null;

  if (logo === null || logo === undefined) {
    logo = 'https://needzappy.com/wp-content/uploads/2023/09/duozappy-1024x1024.png';
  }

  // 5, 6, 8, 12, 24, 48
  switch (device.slots) {
    case 6:
      // TODO find correct image
      logo = 'https://cdn.aiescape.io/zappy-device-6.jpg';
      break;
    case 12:
      // correct image
      logo = 'https://cdn.aiescape.io/zappy-devices-12.jpg';
      break;
    case 24:
      // correct image
      logo = 'https://cdn.aiescape.io/zappy-devices-24.jpg';
      break;
    case 48:
      // correct image
      logo = 'https://cdn.aiescape.io/zappy-devices-48.jpg';
      break;
    case 96:
      // correct image (rendering)
      logo = 'https://needzappy.com/wp-content/uploads/2023/09/kiosk3-1024x1024.png';
      break;
    default:
      logo = 'https://cdn.aiescape.io/zappy-devices-12.jpg';
      break;
  }

  return <img alt={`Logo for ${device.device_id}`} src={logo} style={{ width: '100%' }} />;
}

export type DeviceDetailsProps = {
  device: DeviceWithVenue;
};

export function DeviceDetails(props: DeviceDetailsProps) {
  const { device } = props;

  return (
    <Container
      header={<Header>{device.device_id}</Header>}
      media={{
        content: <DeviceImage device={device} />,
        position: 'side',
        width: '60%',
      }}
      fitHeight
    >
      <ColumnLayout columns={2} variant="text-grid">
        <div>
          <Box variant="awsui-key-label">Venue</Box>
          <VenueLink venue={device.venue} />
        </div>
        <div>
          <Box variant="awsui-key-label">Position</Box>
          <Box variant="p">{device.kiosk?.position_description ?? 'N/A'}</Box>
        </div>
        <div>
          <Box variant="awsui-key-label">Device Status</Box>
          <DeviceStatusIcon status={device.status} />
        </div>
        <div>
          <Box variant="awsui-key-label">Installation Date</Box>
          <Box variant="p">{device.kiosk?.install_date ?? 'N/A'}</Box>
        </div>
        <div>
          <Box variant="awsui-key-label">Uninstall Date</Box>
          <Box variant="p">{device.kiosk?.uninstall_date ?? 'N/A'}</Box>
        </div>
        <div>
          <Box variant="awsui-key-label">Full Slots</Box>
          <PercentStatus
            max_percent={50}
            min_percent={10}
            total={device.slots}
            value={device.status === 'Unknown' ? undefined : device.slots - device.active_slots}
          />
        </div>
        <div>
          <Box variant="awsui-key-label">Type</Box>
          <Box variant="p">{device.vendor.name === 'Relink' ? 'Uber' : 'Kiosk'}</Box>
        </div>
      </ColumnLayout>
    </Container>
  );
}

function Content(props: { item: DeviceWithVenue }) {
  const { item } = props;


  const past = true;
  const [now, setNow] = useState(new Date());
  const orderQuery = useLocalFilterState();
  const orderRange = useLocalRangeState(past, 'last-1-month');
  const orderPage = useLocalPageState();
  const orderSort = useLocalSortState('rent_time', 'desc');

  const metaQuery = useQuery({
    queryKey: ['orders', 'meta', now],
    queryFn: getOrdersMeta,
    staleTime: 3_600_000, // 1 hour
  });

  const { options, properties } = useMemo(() => {
    if (metaQuery.data) {
      return {
        options: metaQuery.data.filteringOptions,
        properties: metaQuery.data.filteringProperties,
      };
    }

    return {
      options: [],
      properties: [],
    };
  }, [metaQuery.data]);

  const refresh = () => {
    setNow(new Date());
  };

  // wait for the meta query to finish before proceeding
  const reportQuery = useQuery({
    queryKey: ['orders', orderQuery.query, orderRange.range, now],
    enabled: metaQuery.isSuccess,
    queryFn: async () => {
      const orders = await getOrderReport(
        {
          operation: orderQuery.query.operation,
          tokens: [
            ...orderQuery.query.tokens,
            {
              value: item.device_id,
              propertyKey: 'device_id',
              operator: '=',
            },
          ],
        },
        orderRange.range,
        now
      );

      return orders;
    },
    staleTime: 300_000, // 5 minutes
  });

  const loading =
    (reportQuery.isPending ||
      reportQuery.isFetching ||
      metaQuery.isPending ||
      metaQuery.isFetching) &&
    !reportQuery.error &&
    !metaQuery.error;

  const error = reportQuery.error || metaQuery.error;

  const itemFilter = (
    <PropertyDateFilter
      filteringProperties={properties}
      loading={loading}
      past={past}
      propertyFilteringOptions={options}
      query={orderQuery.query}
      range={orderRange.range ?? null}
      setQuery={(q) => {
        orderQuery.setQuery?.(q);
      }}
      setRange={(r) => {
        orderRange.setRange?.(r);
      }}
    />
  );

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, s: 6 } },
        { colspan: { default: 12, s: 6 } },
        { colspan: { default: 12, s: 12 } },
        { colspan: { default: 12, s: 12 } },
      ]}
    >
      <DeviceDetails device={item} />
      <SpaceBetween direction="vertical" size="s">
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <Button
                    disabled={loading}
                    iconName="refresh"
                    variant="normal"
                    onClick={refresh}
                  />
                </SpaceBetween>
              }
            >
              Report Settings
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="s">
            {itemFilter}
            {reportQuery.data && (
              <OrdersReport
                description={`Overview for ${item.device_id} within the specified time range`}
                report={reportQuery.data}
                title={`${item.device_id} Overview`}
              />
            )}
          </SpaceBetween>
        </Container>
        {(loading || error) && <LoadingOrError error={error} loading={loading} retry={refresh} />}
      </SpaceBetween>
      <OrderTable
        {...orderQuery}
        {...orderRange}
        {...orderPage}
        {...orderSort}
        getItems={(query, page, pageSize, range, sortingField, sortDirection, now) =>
          getDeviceOrders(item.uuid, query, page, pageSize, range, sortingField, sortDirection, now)
        }
        getMeta={() => getDeviceOrdersMeta(item.uuid)}
        hiddenColumns={['device_id']}
        hideFilter
        now={now}
        setNow={setNow}
        variant="container"
      />
      {/* <DeviceReport deviceId={deviceId} /> */}
      <DeviceHeartbeatGraph deviceId={item.uuid} />
    </Grid>
  );
}

export default function DevicePage() {
  const params = useParams();
  // Device UUID
  const deviceId = params.deviceId ?? '';

  const { data, isPending, isFetching, error, refetch } = useQuery({
    queryKey: ['device', deviceId],
    queryFn: () => getDevice(deviceId),
    staleTime: 3_600_000, // 1 hour
  });

  const loading = (isPending || isFetching) && !error;

  return (
    <ViewLayout
      breadcrumbs={[
        { text: 'Devices', href: '/devices' },
        {
          text: data ? data.device_id : loading ? ' ' : ' ',
          href: '',
        },
      ]}
      contentType="dashboard"
      header={<ZappyHeader title={data ? `Device ${data.device_id}` : ' '} />}
      headerVariant="high-contrast"
    >
      {loading || error || !data ? (
        <LoadingOrError
          error={error}
          loading={loading}
          retry={() => {
            refetch();
          }}
        />
      ) : (
        <Content item={data} />
      )}
    </ViewLayout>
  );
}
