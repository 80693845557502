import {
  Box,
  Button,
  Container,
  DatePicker,
  FormField,
  Grid,
  Header,
  KeyValuePairs,
  Link,
  Modal,
  PieChart,
  Popover,
  Select,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import { useState } from 'react';

import { DashboardReport } from '../common/types';
import { formatFinancial } from '../common/formatting';

import { CenteredHeader, ValueComparison } from './reporting';

export type DashboardMetricItem = {
  label: string;
  value?: number;
  link?: string;
};

export type DashboardMetricChartProps = {
  items: DashboardMetricItem[];
  formatter?: (x: number) => string;
  header: string;
};

export function DashboardMetricChart(props: DashboardMetricChartProps) {
  const formatter = props.formatter || ((x: number) => formatFinancial(x, 0));
  const items = props.items.filter((item) => item.value !== undefined);
  const total = items.reduce((acc, item) => acc + (item.value || 0), 0);

  return (
    <PieChart
      data={items.map((item) => ({
        title: item.label,
        value: item.value || 0,
        link: item.link,
      }))}
      detailPopoverContent={(datum) => [
        { key: 'Value', value: formatter(datum.value) },
        { key: 'Percentage', value: `${((datum.value / total) * 100).toFixed(0)}%` },
        ...(datum.link
          ? [
              {
                key: 'See Details',
                value: <Link href={datum.link} target="_blank" variant="info" external />,
              },
            ]
          : []),
      ]}
      innerMetricDescription={props.header}
      innerMetricValue={formatter(total)}
      segmentDescription={(datum, sum) =>
        `${formatter(datum.value)}, ${((datum.value / sum) * 100).toFixed(0)}%`
      }
      size="medium"
      variant="donut"
      hideFilter
      hideLegend
    />
  );
}

export type DashboardMetricProps = {
  header: string;
  value: number | undefined;
  previousValue?: number;
  loading?: boolean;
  error?: Error | null;
  formatter?: (x: number) => string;
  description?: React.ReactNode;
  inverted?: boolean;
  variant?: 'percentage' | 'difference';
  insideContainer?: boolean;
  link?: string;
  info?: string;
  items?: DashboardMetricItem[];
};

export function DashboardMetric(props: DashboardMetricProps) {
  const [open, setOpen] = useState(false);

  const formatter = props.formatter || ((x: number) => formatFinancial(x, 0));
  const content = (
    <Box textAlign="center" variant="div">
      {props.loading ? (
        <Spinner />
      ) : props.error ? (
        '-'
      ) : props.previousValue !== undefined && props.value !== undefined ? (
        <ValueComparison
          formatter={formatter}
          inverted={props.inverted}
          previousValue={props.previousValue}
          value={props.value}
          variant={props.variant}
        />
      ) : props.value ? (
        formatter(props.value)
      ) : (
        '-'
      )}
    </Box>
  );
  const modal = !props.loading && props.value !== undefined && (
    <Modal
      closeAriaLabel="Close"
      header={
        <Header description={props.description} variant="h2">
          {props.header}
        </Header>
      }
      visible={open}
      onDismiss={() => setOpen(false)}
    >
      <SpaceBetween direction="vertical" size="xs">
        {props.info && <Box variant="p">{props.info}</Box>}
        <hr />
        {props.items && props.items.length > 0 ? (
          <DashboardMetricChart formatter={formatter} header={props.header} items={props.items} />
        ) : (
          <KeyValuePairs
            columns={1}
            items={[
              {
                label: props.header,
                value: props.value ? formatter(props.value) : '-',
                info: props.link ? (
                  <Link href={props.link} target="_blank" variant="info" external />
                ) : undefined,
              },
              ...(props.items
                ? props.items.map((item) => ({
                    label: item.label,
                    value: item.value ? formatter(item.value) : undefined,
                    info: item.link ? (
                      <Link href={item.link} target="_blank" variant="info" external />
                    ) : undefined,
                  }))
                : []),
            ]}
          />
        )}
      </SpaceBetween>
    </Modal>
  );

  return (
    <>
      <Button
        className="dashboard-metric"
        disabled={props.loading}
        variant="link"
        fullWidth
        onClick={() => {
          setOpen(true);
        }}
      >
        <SpaceBetween direction="vertical" size="xxxs">
          <CenteredHeader description={props.description}>{props.header}</CenteredHeader>
          {content}
        </SpaceBetween>
      </Button>
      {modal}
    </>
  );
}

export type DashboardMetricsProps = {
  days: number;
  report?: DashboardReport;
  loading: boolean;
  error?: Error | null;
};

export function DashboardMetrics(props: DashboardMetricsProps) {
  const { days, report, loading, error } = props;

  return (
    <Grid
      gridDefinition={[
        // Row 1
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        // Row 2
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        // Row 3
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        // Row 4
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        // Row 5
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        // Row 6
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
        { colspan: { default: 12, xxs: 6, xs: 4, s: 3 } },
      ]}
    >
      {/* Row 1 */}
      <DashboardMetric
        description={`Total Revenue over the last ${days} days`}
        error={error}
        header="Total Revenue"
        info="Total revenue is the sum of all revenue generated over a given period. This includes revenue from rentals, as well as sponsorship revenue from contracts with sponsors."
        items={[
          {
            label: 'Rentals',
            value: (report?.report.rental_revenue ?? 0) + (report?.report.sales_revenue ?? 0),
          },
          { label: 'Sponsorships', value: report?.report.sponsorship_revenue },
        ]}
        loading={loading}
        previousValue={report?.prior_report.total_revenue}
        value={report?.report.total_revenue}
      />

      <DashboardMetric
        description={`Annualized Run Rate for the last ${days} days`}
        error={error}
        header="ARR"
        info="Annualized Run Rate (ARR) is a metric that projects a company’s yearly revenue based on its current revenue run rate. ARR is calculated by multiplying the rental revenue for a single time period by the number of periods in a year, along with including sponsorship revenue from contracts with sponsors."
        items={[
          {
            label: 'Rentals',
            value:
              (report?.report.arr_rental_revenue ?? 0) + (report?.report.arr_sales_revenue ?? 0),
          },
          { label: 'Sponsorships', value: report?.report.arr_sponsorship_revenue },
        ]}
        loading={loading}
        previousValue={report?.prior_report.arr}
        value={report?.report.arr}
      />

      <DashboardMetric
        description="Cumulative Fiscal Year-to-Date Revenue"
        error={error}
        header="YTD Revenue"
        info="YTD revenue is the sum of all revenue generated over a given period. This includes revenue from rentals, as well as sponsorship revenue from contracts with sponsors."
        items={[
          {
            label: 'Rentals',
            value:
              (report?.ytd_report.rental_revenue ?? 0) + (report?.ytd_report.sales_revenue ?? 0),
          },
          { label: 'Sponsorships', value: report?.ytd_report.sponsorship_revenue },
        ]}
        loading={loading}
        previousValue={
          report ? report.ytd_report.total_revenue - report.report.total_revenue : undefined
        }
        value={report?.ytd_report.total_revenue}
        variant="difference"
      />

      {/* <DashboardMetric
        header="Sponsor Revenue"
        description="Annualized Sponsor Revenue"
        error={error}
        info="Annualized Sponsor Revenue is the total revenue generated from sponsorship contracts with sponsors over a given period. This metric is calculated by multiplying the sponsorship revenue for a single time period by the number of periods in a year."
        loading={loading}
        previousValue={report?.prior_report.sponsorship_revenue}
        value={report?.report.sponsorship_revenue}
        variant="difference"
      /> */}

      {/* <DashboardMetric
        header="Rental Revenue"
        description={`Rental revenue for the last ${days} days`}
        error={error}
        info="Rental revenue is the total revenue generated from battery rental orders by customers over a given period."
        loading={loading}
        previousValue={report?.prior_report.rental_revenue}
        value={report?.report.rental_revenue}
      />

      <DashboardMetric
        header="Sales Revenue"
        description={`Sales revenue for the last ${days} days`}
        error={error}
        info="Sales revenue is the total revenue generated from battery rental orders that were converted to sales by customers keeping the battery."
        loading={loading}
        previousValue={report?.prior_report.sales_revenue}
        value={report?.report.sales_revenue}
      /> */}

      {/* Row 2 */}

      <DashboardMetric
        description={`Total Rentals over the last ${days} days`}
        error={error}
        formatter={(x) => x.toLocaleString()}
        header="Rentals"
        info="The total number of battery rentals by customers over a given period."
        items={[
          {
            label: 'Returns & Live Rentals',
            value: report?.report.rentals,
            link: '/orders?k=rent_status&o=%21%3D&v=Overtime',
          },
          {
            label: 'Non-Returns',
            value: report?.report.sales,
            link: '/orders?k=rent_status&o=%3D&v=Overtime',
          },
        ]}
        link="/orders"
        loading={loading}
        previousValue={report?.prior_report.total_orders}
        value={report?.report.total_orders}
      />

      <DashboardMetric
        description={`Rental minutes over the last ${days} days`}
        error={error}
        formatter={(x) => x.toLocaleString()}
        header="Rental Minutes"
        info="The total number of battery rental minutes by customers over a given period."
        loading={loading}
        previousValue={report?.prior_report.total_rental_minutes}
        value={report?.report.total_rental_minutes}
      />

      {/* Row 3 */}

      <DashboardMetric
        description="Revenue per Slot per Month"
        error={error}
        formatter={(x) => formatFinancial(x, 2)}
        header="RPM"
        info="Revenue per Slot per Month (RPM) is the average revenue generated by a single kiosk slot over a given period. This metric is calculated by dividing the total revenue generated by a kiosk by the total number of slots in the kiosk, which is then divided by the number of months in the period. Kiosks are ignored if they have not seen any rentals in the period or have not been installed for the entire period."
        loading={loading}
        previousValue={report?.prior_report.device_avg_rps}
        value={report?.report.device_avg_rps}
      />

      <DashboardMetric
        description="Revenue per Device per Month"
        error={error}
        formatter={(x) => formatFinancial(x, 2)}
        header="RPD"
        info="Revenue per Device per Month (RPD) is the average revenue generated by a single kiosk over a given period. This metric is calculated by dividing the total revenue generated by a kiosk by the total number of kiosks, which is then divided by the number of months in the period. Kiosks are ignored if they have not seen any rentals in the period or have not been installed for the entire period."
        loading={loading}
        previousValue={report?.prior_report.device_avg_rev}
        value={report?.report.device_avg_rev}
      />

      {/* Row 2 */}
      <DashboardMetric
        description={
          days === 30
            ? 'Average Revenue per Rental over the last Month'
            : `Average Revenue per Rental over the last ${days} days`
        }
        error={error}
        formatter={(x) => formatFinancial(x, 2)}
        header="Basket Size"
        info="Average Revenue per Rental is the average revenue generated by a single rental over a given period, commonly referred to as Basket Size. This metric is calculated by dividing the total revenue generated by the total number of rentals in the period."
        loading={loading}
        previousValue={report?.prior_report.avg_basket}
        value={report?.report.avg_basket}
      />

      <DashboardMetric
        description={`Average Rental Minutes per rental over the last ${days} days`}
        error={error}
        formatter={(x) => x.toFixed(0)}
        header="ARM"
        info="Average Rental Minutes per Rental is the average number of minutes a customer rents a battery over a given period. This metric is calculated by dividing the total number of rental minutes by the total number of rentals in the period."
        loading={loading}
        previousValue={report?.prior_report.avg_rental_minutes}
        value={report?.report.avg_rental_minutes}
      />

      {/* Row 3 */}

      {/* 
      <DashboardMetric
        description={`Kiosk devices over the last ${days} days`}
        error={error}
        formatter={(x) => x.toLocaleString()}
        loading={loading}
        previousValue={report?.prior_report.total_kiosk_devices}
        value={report?.report.total_kiosk_devices}
      >
        Kiosk Devices
      </DashboardMetric> */}

      <DashboardMetric
        description={`Devices live over the last ${days} days`}
        error={error}
        formatter={(x) => x.toLocaleString()}
        header="Deployed Devices"
        info="Deployed devices are devices that have been installed and are live at any point over the given period."
        loading={loading}
        previousValue={report?.prior_report.total_devices}
        value={report?.report.total_devices}
      />

      {/* Row 5 */}
      <DashboardMetric
        description="Total device slots for deployed devices"
        error={error}
        formatter={(x) => x.toLocaleString()}
        header="Total Slots"
        info="Total Slots are the total number of slots available in kiosks for holding devices. This includes full slots, which are slots that are currently occupied by batteries, and empty slots, which are slots that are currently empty and available for batteries."
        items={[
          { label: 'Full Slots', value: report?.report.full_slots },
          { label: 'Empty Slots', value: report?.report.empty_slots },
        ]}
        loading={loading}
        previousValue={report?.prior_report.total_slots}
        value={report?.report.total_slots}
      />

      <DashboardMetric
        description="Year-to-Date Rideshare signups"
        error={error}
        formatter={(x) => x.toLocaleString()}
        header="Rideshare Signups"
        info="Rideshare signups are the total number of rideshare drivers who have signed up for including Zappy batteries in their rideshare vehicles over the given period."
        loading={loading}
        previousValue={report?.prior_report.rideshare_signups}
        value={report?.ytd_report.rideshare_signups}
      />

      <DashboardMetric
        description="Year-to-Date Apple iOS App Downloads"
        error={error}
        formatter={(x) => x.toLocaleString()}
        header="App Downloads"
        info="Apple iOS App Downloads are the total number of downloads of the Zappy app from the Apple App Store over the given period."
        loading={loading}
        previousValue={report?.prior_report.apple_downloads}
        value={report?.ytd_report.apple_downloads}
      />
    </Grid>
  );
}
