import { useContext, useState } from 'react';
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Grid,
  Link,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { useMutation } from '@tanstack/react-query';

import { UserLogin, UserTokenInfo } from '../common/types';
import UserContext from '../common/user';
import { ViewLayout } from '../components/ViewLayout';
import { loginRequest } from '../common/api/auth';
import { LoadingOrError } from '../common/loading';

export default function Login() {
  const { setUser } = useContext(UserContext);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [passwordErrorText, setPasswordErrorText] = useState<string>('');

  const navigate = useNavigate();

  const loginMutation = useMutation({
    mutationFn: (info: UserLogin) => loginRequest(info),
    onSuccess: (response) => {
      const token = response.token;
      const refresh_token = response.refresh_token;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      setUser(token ? (jwt(token) as UserTokenInfo) : null);

      // get redirect from query string
      const params = new URLSearchParams(window.location.search);
      const redirect = params.get('redirect');
      // decode redirect from encodeURIComponent
      const decodedRedirect = redirect ? decodeURIComponent(redirect) : null;

      if (decodedRedirect) {
        navigate(decodedRedirect);
      } else {
        navigate(`/`);
      }
    },
  });

  const login = async () => {
    let formError = false;

    if (!email) {
      setEmailErrorText('Email is required');
      formError = true;
    } else {
      setEmailErrorText('');
    }

    if (!password) {
      setPasswordErrorText('Password is required');
      formError = true;
    } else {
      setPasswordErrorText('');
    }

    if (formError) {
      return;
    }

    const info: UserLogin = {
      email,
      password,
    };
    loginMutation.mutate(info);
  };

  return (
    <ViewLayout
      breadcrumbs={[{ text: 'Login', href: '' }]}
      header={<Header variant="h1">Login to Zappy</Header>}
      hideNavigation
    >
      <Grid
        gridDefinition={[
          {
            colspan: {
              default: 12,
              l: 6,
              s: 4,
            },
          },
        ]}
      >
        <SpaceBetween direction="vertical" size="l">
          <Container header={<Header variant="h2">Login to your Account</Header>}>
            <form onSubmit={(event) => event.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button loading={loginMutation.isPending} variant="primary" onClick={login}>
                      Login
                    </Button>
                  </SpaceBetween>
                }
              >
                <SpaceBetween size="l">
                  <FormField errorText={emailErrorText} label="Email">
                    <Input
                      disabled={loginMutation.isPending}
                      placeholder="Enter email..."
                      type="email"
                      value={email}
                      onChange={({ detail: { value } }) => setEmail(value)}
                    />
                  </FormField>

                  <FormField errorText={passwordErrorText} label="Password">
                    <Input
                      disabled={loginMutation.isPending}
                      placeholder="Enter password..."
                      type="password"
                      value={password}
                      onChange={({ detail: { value } }) => setPassword(value)}
                    />
                  </FormField>
                  <SpaceBetween size="xxxs">
                    <Box variant="p">
                      <Link
                        href="/reset"
                        onFollow={(event) => {
                          event.preventDefault();
                          navigate('/reset');
                        }}
                      >
                        Forgot password?
                      </Link>
                    </Box>
                  </SpaceBetween>
                </SpaceBetween>
              </Form>
            </form>
          </Container>
          {loginMutation.error && (
            <LoadingOrError
              error={loginMutation.error}
              loading={loginMutation.isPending}
              retry={login}
            />
          )}
        </SpaceBetween>
      </Grid>
    </ViewLayout>
  );
}
