import { AreaChart, Box, Container, DateRangePicker, Header } from '@cloudscape-design/components';
import { useEffect, useMemo, useState } from 'react';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { DeviceHeartbeat } from '../../common/types';
import { useApiNoBody } from '../../common/api';
import { checkIsValidRange } from '../../common/range';
import { createFilter } from '../../common/filtering/filter';
import { rangeStartEnd } from '../../common/filtering/filter';

export function useRangeState() {
  const [range, setRange] = useState<DateRangePickerProps.Value | null>({
    key: 'previous-1-week',
    amount: 1,
    unit: 'week',
    type: 'relative',
  });

  return {
    range,
    setRange,
  };
}

export function useDeviceHeartbeat(deviceId: string) {
  const [apiItems, setApiItems] = useState<DeviceHeartbeat[]>([]);
  const { range, setRange } = useRangeState();

  let apiPath = `/devices/${deviceId}/heartbeats`;

  // add query params to apiPath
  const params = new URLSearchParams();

  if (range) {
    const { start, end } = rangeStartEnd(range);
    // correct format and correct UTC time
    params.append('start', start.toISOString());
    params.append('end', end.toISOString());
  }

  apiPath += `?${params.toString()}`;

  const [api, error, loading] = useApiNoBody<DeviceHeartbeat[]>(apiPath, 'GET', setApiItems);

  const fetchApi = async () => {
    await api();
  };

  const filterTable = createFilter<DeviceHeartbeat>(
    {
      operation: 'and',
      tokens: [],
    },
    [],
  );

  const filteredItems = apiItems.filter(filterTable);

  return {
    fetchApi,
    range,
    setRange,
    filteredItems,
    loading,
    error,
  };
}

//
const IGNORE_TIME = 30 * 60 * 1000;

export function heartbeatToOnlineSeries(heartbeats: DeviceHeartbeat[]) {
  if (heartbeats.length === 0) {
    return [
      {
        x: new Date(),
        y: 0,
      },
    ];
  }

  const series = [];
  // each heartbeat has a start_time and an optional end_time.
  // If end_time is not present, the device is still online.
  // Any difference between the end time and the next start time is a period of offline time.
  // However, small periods (say 30 minutes) of offline time are not interesting, so we will ignore them.

  let prevStart = null;
  let prevEnd = null;

  for (let i = 0; i < heartbeats.length; i++) {
    const current = heartbeats[i];
    const currentStart = new Date(current.start_time + 'Z');
    const currentEnd = current.end_time ? new Date(current.end_time + 'Z') : null;

    if (prevStart === null || prevEnd === null) {
      series.push({
        x: currentStart,
        y: 1,
      });
    } else {
      // if the difference is more than 10 minutes, we will consider it as a period of offline time
      const diff = currentStart.getTime() - prevEnd.getTime();

      if (diff > IGNORE_TIME) {
        series.push({
          x: prevEnd,
          y: 0,
        });
        series.push({
          x: currentStart,
          y: 0,
        });
        series.push({
          x: currentStart,
          y: 1,
        });
      }
    }

    if (currentEnd !== null) {
      series.push({
        x: currentEnd,
        y: 1,
      });
    }

    prevStart = currentStart;
    prevEnd = currentEnd;

    if (i === heartbeats.length - 1) {
      if (currentEnd === null) {
        series.push({
          x: new Date(),
          y: 1,
        });
      } else {
        series.push({
          x: currentEnd,
          y: 0,
        });
        series.push({
          x: new Date(),
          y: 0,
        });
      }
    }
  }

  return series;
}

export type DeviceHeartbeatGraphProps = {
  deviceId: string;
};

export function DeviceHeartbeatGraph(props: DeviceHeartbeatGraphProps) {
  const { deviceId } = props;
  const { range, setRange, filteredItems, loading, error, fetchApi } = useDeviceHeartbeat(deviceId);

  const data = useMemo(() => {
    return heartbeatToOnlineSeries(filteredItems);
  }, [filteredItems]);

  useEffect(() => {
    fetchApi();
  }, [deviceId, range]);

  return (
    <Container header={<Header>Historical Status</Header>}>
      <AreaChart
        additionalFilters={
          <DateRangePicker
            isValidRange={checkIsValidRange}
            placeholder="Filter by a date and time range"
            relativeOptions={[
              {
                key: 'previous-12-hours',
                amount: 12,
                unit: 'hour',
                type: 'relative',
              },
              {
                key: 'previous-1-day',
                amount: 1,
                unit: 'day',
                type: 'relative',
              },
              {
                key: 'previous-1-week',
                amount: 1,
                unit: 'week',
                type: 'relative',
              },
              {
                key: 'previous-1-month',
                amount: 1,
                unit: 'month',
                type: 'relative',
              },
              {
                key: 'previous-1-year',
                amount: 1,
                unit: 'year',
                type: 'relative',
              },
            ]}
            value={range}
            expandToViewport
            onChange={({ detail }) => setRange(detail.value)}
          />
        }
        ariaLabel="Device status line chart"
        empty={
          <Box color="inherit" textAlign="center">
            <b>No data available</b>
            <Box color="inherit" variant="p">
              There is no data available for the selected time range.
            </Box>
          </Box>
        }
        errorText={error ?? undefined}
        height={105}
        i18nStrings={{
          xTickFormatter: (e) =>
            e
              .toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })
              .split(',')
              .join('\n'),
          yTickFormatter: function numberFormatter(_e) {
            return '';
          },
        }}
        loadingText=""
        series={[
          {
            title: 'Online',
            type: 'area',
            data,
            valueFormatter: function numberFormatter(e) {
              return e === 1 ? 'Yes' : 'No';
            },
          },
          {
            title: 'Offline',
            type: 'area',
            data: data.map((d) => ({ ...d, y: d.y === 0 ? 1 : 0 })),
            valueFormatter: function numberFormatter(e) {
              return e === 1 ? 'Yes' : 'No';
            },
          },
        ]}
        statusType={loading ? 'loading' : error ? 'error' : 'finished'}
        xScaleType="time"
        xTitle="Time"
        yDomain={[0, 1]}
        yTitle="Status"
        hideFilter
      />
    </Container>
  );
}
