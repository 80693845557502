import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { DashboardReport } from '../types';
import { rangeStartEnd } from '../filtering/filter';

import axiosInstance from './axiosInstance';

export async function getDashboardReport(end: Date, days: number) {
  const response = await axiosInstance.get('/reports/dashboard', {
    params: {
      end: end.toISOString(),
      days,
    },
  });

  return response.data as DashboardReport;
}

export async function getOrderReport(
  query?: PropertyFilterQuery,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
) {
  const rangeName = 'rent_time';
  const route = '/reports/orders';

  const operators = query ? query.tokens.map((token) => token.operator) : [];
  const propertyKeys = query ? query.tokens.map((token) => token.propertyKey as string) : [];
  const values = query ? query.tokens.map((token) => token.value as string) : [];

  if (range) {
    const { start, end } = rangeStartEnd(range, now);
    const startIso = start.toISOString();
    const endIso = end.toISOString();
    let rangePropertyKey = 'created_at';

    if (rangeName) {
      rangePropertyKey = rangeName;
    }
    propertyKeys.push(rangePropertyKey);
    operators.push('>=');
    values.push(startIso);
    propertyKeys.push(rangePropertyKey);
    operators.push('<=');
    values.push(endIso);
  }

  const op = query ? (query.operation === 'or' ? 'or' : undefined) : undefined;
  const response = await axiosInstance.get(route, {
    params: {
      op,
      o: operators,
      k: propertyKeys,
      v: values,
    },
    // Get rid of [] in query params
    // https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
}
