import { getDevices } from '../common/api/devices';
import { getDevicesMeta } from '../common/api/devices';
import { DeviceWithVenue, Order } from '../common/types';
import { DeviceStatusIcon } from '../common/devices';
import { PercentStatus } from '../common/percent-status';

import { DefinedQueryTableProps, QueryTable } from './QueryTable';
import { DeviceLink } from './DeviceLink';
import { VenueLink } from './VenueLink';

export type DeviceTableProps = DefinedQueryTableProps<DeviceWithVenue> & {
  description?: string;
};

export function DeviceTable(props: DeviceTableProps) {
  const getItems = props.getItems ?? getDevices;
  const getMeta = props.getMeta ?? getDevicesMeta;

  return (
    <QueryTable
      {...props}
      columnDefinitions={[
        {
          id: 'device_id',
          sortingField: 'device_id',
          header: 'ID',
          cell: (e: DeviceWithVenue) => <DeviceLink device={e} />,
        },
        {
          id: 'venue_name',
          sortingField: 'venue_name',
          header: 'Venue',
          cell: (e: DeviceWithVenue) => <VenueLink venue={e.venue} />,
        },
        {
          id: 'position',
          // sortingField: 'position',
          header: 'Position',
          cell: (e: DeviceWithVenue) => e.kiosk?.position_description ?? 'N/A',
        },
        // {
        //   id: 'source',
        //   header: 'Source',
        //   cell: (e: DeviceWithVenue) => (e.vendor.name === 'Relink' ? 'Uber' : 'Kiosk'),
        //   sortingField: 'source',
        //   sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
        //     const a_name = a.vendor.name === 'Relink' ? 'Uber' : 'Kiosk';
        //     const b_name = b.vendor.name === 'Relink' ? 'Uber' : 'Kiosk';

        //     return a_name.localeCompare(b_name);
        //   },
        // },
        {
          id: 'full_slots',
          header: 'Full Slots',
          cell: (e: DeviceWithVenue) => (
            <PercentStatus
              max_percent={50}
              min_percent={10}
              total={e.slots}
              value={e.status === 'Unknown' ? undefined : e.slots - e.active_slots}
            />
          ),
          // sortingField: 'full_slots',
          // sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
          //   return (
          //     ((a.total_slots === 0 ? -2 : a.full_slots) ?? -1) -
          //     ((b.total_slots === 0 ? -2 : b.full_slots) ?? -1)
          //   );
          // },
        },
        {
          id: 'status',
          sortingField: 'status',
          header: 'Status',
          cell: (e: DeviceWithVenue) => <DeviceStatusIcon status={e.status} />,
        },
        {
          id: 'fulfillment',
          header: 'Fulfillment',
          cell: (e: DeviceWithVenue) => (e.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed'),
          // sortingField: 'fulfillment',
          // sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
          //   return (a.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed').localeCompare(
          //     b.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed',
          //   );
          // },
        },
      ]}
      description={props.description ?? 'Zappy devices from which batteries are rented'}
      endpointName="devices"
      getItems={getItems}
      getMeta={getMeta}
      resourceName="Devices"
      hideDateRange
    />
  );
}
