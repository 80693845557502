import { Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';
import { useState } from 'react';

import { useUrlFilterState, useUrlRangeState, useUrlSortState } from '../common/filtering/filter';
import { useUrlPageState } from '../common/filtering/filter';
import { OrderTable } from '../components/OrderTable';
import { ViewLayout } from '../components/ViewLayout';

function Content() {
  const [now, setNow] = useState(new Date());
  const queryProps = useUrlFilterState();
  const rangeProps = useUrlRangeState(true, 'last-1-month');
  const pageProps = useUrlPageState();
  const sortProps = useUrlSortState('rent_time', 'desc');

  return (
    <OrderTable
      {...queryProps}
      {...rangeProps}
      {...pageProps}
      {...sortProps}
      now={now}
      setNow={setNow}
    />
  );
}

export default function OrdersPage() {
  return (
    <ViewLayout breadcrumbs={[{ text: 'Rentals', href: '' }]} contentType="table">
      <Content />
    </ViewLayout>
  );
}
