import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { Order, OrdersReportRead } from '../types';

import { getAllQueryResource, getQueryResource, getReportResource } from './query';
import { getMetaQueryResource } from './meta';

export async function getOrders(
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getQueryResource<Order>(
    '/orders',
    query,
    page,
    limit,
    range,
    now,
    'rent_time',
    sortingField,
    sortDirection,
  );
}

export async function getOrdersMeta() {
  return await getMetaQueryResource('/orders/meta');
}

export async function getAllOrders(
  query?: PropertyFilterQuery,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getAllQueryResource<Order>(
    '/orders',
    query,
    1000,
    range,
    now,
    'rent_time',
    sortingField,
    sortDirection,
  );
}

export async function getOrderReport(
  query?: PropertyFilterQuery,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
) {
  return await getReportResource<OrdersReportRead>(
    '/orders/report',
    query,
    range,
    now,
    'rent_time',
  );
}
