import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { ReactNode, useMemo } from 'react';

import {
  DeviceWithOrders,
  DeviceWithUptime,
  Order,
  Venue,
  VenueReportRead,
  VenueWithOrders,
  VenueWithOrdersAndStats,
} from '../common/types';
import { getHeaderCounterText } from '../common/full-header';
import { VenueLink } from '../components/VenueLink';
import { exportExcel } from '../common/excel';
import { computeStats } from '../common/stats';
import { rangeStartEnd } from '../common/filtering/filter';

function isUndefinedRps(rps: number | undefined) {
  return rps === undefined || isNaN(rps) || !isFinite(rps);
}

export type VenueOrdersTableProps = {
  refresh: () => void;
  reports: VenueReportRead[];
  loading: boolean;
  error: Error | null;
  actions?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  pageSize?: number;
};

export function VenueOrdersTable(props: VenueOrdersTableProps) {
  const pageSize = props.pageSize ?? 15;
  const resourceName = 'Venue';

  const { refresh, reports, loading, error } = props;

  const { items, collectionProps, paginationProps } = useCollection(reports, {
    pagination: { pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'revenue',
          sortingComparator(a, b) {
            const x = a.total_revenue;
            const y = b.total_revenue;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...paginationProps}
      columnDefinitions={[
        {
          id: 'name',
          header: 'Name',
          cell: (e) => (
            <VenueLink
              venue={{
                name: e.venue_name,
                uuid: e.venue_uuid,
                currency: e.venue_currency,
                id: -1,
                venue_id: '',
              }}
            />
          ),
          sortingField: 'name',
          sortingComparator(a, b) {
            const x = a.venue_name;
            const y = b.venue_name;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        // {
        //   id: 'type',
        //   header: 'Type',
        //   cell: (e) => e.venueData.type ?? 'Uncategorized',
        //   sortingField: 'type',
        //   sortingComparator(a, b) {
        //     const x = a.venueData.type ?? 'Uncategorized';
        //     const y = b.venueData.type ?? 'Uncategorized';

        //     return x < y ? -1 : x > y ? 1 : 0;
        //   },
        // },
        {
          id: 'revenue',
          header: 'Revenue',
          cell: (e) =>
            `${e.total_revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} ${
              e.venue_currency
            }`,
          sortingField: 'revenue',
          sortingComparator(a, b) {
            const x = a.total_revenue;
            const y = b.total_revenue;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'orders',
          header: 'Orders',
          cell: (e) => `${e.total_rentals.toLocaleString()}`,
          sortingField: 'orders',
          sortingComparator(a, b) {
            const x = a.total_rentals;
            const y = b.total_rentals;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'rpm',
          header: 'RPM',
          cell: (e) => (isUndefinedRps(e.rps) ? 'N/A' : `$${e.rps.toFixed(2)}`),
          sortingField: 'rpm',
          sortingComparator(a, b) {
            const x = a.rps;
            const y = b.rps;

            if (isUndefinedRps(x) && isUndefinedRps(y)) {
              return 0;
            }

            if (isUndefinedRps(x)) {
              return -1;
            }

            if (isUndefinedRps(y)) {
              return 1;
            }

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'tpm',
          header: 'TPM',
          cell: (e) => (isUndefinedRps(e.rps) ? 'N/A' : `${e.tps.toFixed(1)}`),
          sortingField: 'tpm',
          sortingComparator(a, b) {
            const x = a.tps;
            const y = b.tps;

            if (isUndefinedRps(x) && isUndefinedRps(y)) {
              return 0;
            }

            if (isUndefinedRps(x)) {
              return -1;
            }

            if (isUndefinedRps(y)) {
              return 1;
            }

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
      ]}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error.message}
                </Box>
              </div>
              <Button onClick={refresh}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      header={
        <Header
          actions={
            props.actions ?? (
              <Button
                disabled={loading}
                iconName="download"
                onClick={() => {
                  exportExcel(
                    reports
                      .map((venue) => ({
                        name: venue.venue_name,
                        revenue: venue.total_revenue,
                        orders: venue.total_rentals,
                        rps: isNaN(venue.rps) ? 'N/A' : venue.rps,
                        tps: isNaN(venue.tps) ? 'N/A' : venue.tps,
                        currency: venue.venue_currency,
                      }))
                      .toSorted((a, b) => b.revenue - a.revenue),
                    'venue_revenue',
                  );
                }}
              />
            )
          }
          counter={getHeaderCounterText(reports, collectionProps.selectedItems)}
          description={props.description}
        >
          {props.title ?? `${resourceName}s`}
        </Header>
      }
      items={items}
      loading={loading}
      loadingText={`Loading ${resourceName.toLowerCase()}s`}
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      trackBy="id"
    />
  );
}
