import { getVenues } from '../common/api/venues';
import { getVenuesMeta } from '../common/api/venues';
import { VenueWithDevices } from '../common/types';

import { DefinedQueryTableProps, QueryTable } from './QueryTable';
import { VenueLink } from './VenueLink';
import { VenueOnlineStatus } from './VenueOnlineStatus';
import { VenueSlotStatus } from './VenueSlotStatus';

export type VenueTableProps = DefinedQueryTableProps<VenueWithDevices> & {
  description?: string;
};

export function VenueTable(props: VenueTableProps) {
  const getItems = props.getItems ?? getVenues;
  const getMeta = props.getMeta ?? getVenuesMeta;

  return (
    <QueryTable
      {...props}
      columnDefinitions={[
        {
          id: 'name',
          sortingField: 'name',
          header: 'Venue',
          cell: (e) => <VenueLink venue={e} />,
        },
        {
          id: 'type',
          sortingField: 'industry_type',
          header: 'Type',
          cell: (e) => e.site?.deal?.industry_type ?? 'Uncategorized',
        },
        {
          id: 'currency',
          sortingField: 'currency',
          header: 'Currency',
          cell: (e) => e.currency ?? 'USD',
        },
        {
          id: 'online',
          header: 'Devices Online',
          cell: (e) => <VenueOnlineStatus venue={e} />,
        },
        {
          id: 'full_slots',
          header: 'Full Slots',
          cell: (e) => <VenueSlotStatus venue={e} />,
        },
      ]}
      description={props.description ?? 'Zappy venues where devices are installed'}
      endpointName="venues"
      getItems={getItems}
      getMeta={getMeta}
      resourceName="Venues"
      hideDateRange
    />
  );
}
