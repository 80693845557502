import {
  Container,
  Box,
  Header,
  SpaceBetween,
  Form,
  StatusIndicator,
  FormField,
  Input,
  Button,
  RadioGroup,
  Multiselect,
} from '@cloudscape-design/components';
import { useEffect, useMemo, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useQuery } from '@tanstack/react-query';

import { DeviceWithVenue, UserInvite, Venue } from '../../common/types';
import { useApiNoBody, useApiNoResponse } from '../../common/api';
import { ROLE_OPTIONS } from '../../common/roles';
import { getAllVenues } from '../../common/api/venues';

export function getVenues(items: DeviceWithVenue[]): Venue[] {
  const venues: Venue[] = [];
  const venueNames = new Set<string>();

  for (const item of items) {
    if (!venueNames.has(item.venue.venue_id)) {
      venues.push(item.venue);
    }
    venueNames.add(item.venue.venue_id);
  }
  venues.sort((a, b) => a.name.localeCompare(b.name));

  return venues;
}

export function useVenues() {
  const [now, setNow] = useState(new Date());

  const query = useQuery({
    queryKey: ['venues', now],
    queryFn: () => getAllVenues(undefined, undefined, undefined, undefined, now),
    staleTime: 86_400_000, // 1 day
  });

  return query;
}

export function InviteUser() {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [venues, setVenues] = useState<OptionDefinition[]>([]);

  const [role, setRole] = useState<string>('investor');

  const { data: allVenues, isLoading: infoLoading, error: infoError } = useVenues();

  useEffect(() => {
    setVenues([]);
  }, [role]);

  const [inviteApi, error, loading] = useApiNoResponse<UserInvite[]>('/invite', 'POST');

  const invite = async () => {
    if (loading) {
      return;
    }

    let formError = false;

    if (!email) {
      setEmailError('Email is required');
      formError = true;
    } else {
      setEmailError('');
    }

    if (formError) {
      return;
    }

    const info: UserInvite = {
      email,
      roles: [role],
      venues: venues.map((venue) => venue.value!),
    };
    const result = await inviteApi([info]);

    if (result.error) {
      setStatus('error');
    } else {
      setEmail('');
      setStatus('success');
    }
  };

  return (
    <Container header={<Header variant="h2">Invite a User</Header>}>
      <Box variant="p">This will send an email with a personalized link to join.</Box>
      <form onSubmit={(event) => event.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button loading={loading} variant="primary" onClick={invite}>
                Invite
              </Button>
            </SpaceBetween>
          }
          errorText={error}
        >
          <SpaceBetween size="l">
            <FormField errorText={emailError} label="Email">
              <Input
                disabled={loading}
                placeholder="Enter email..."
                type="email"
                value={email}
                onChange={({ detail: { value } }) => setEmail(value)}
              />
            </FormField>
            <FormField description="Select role for user." label="Role">
              <RadioGroup
                items={ROLE_OPTIONS}
                value={role}
                onChange={({ detail: { value } }) => setRole(value)}
              />
            </FormField>
            {role === 'venue' ? (
              <FormField description="Select venues for venue account." label="Venues">
                <Multiselect
                  errorText={infoError?.message}
                  filteringType="auto"
                  options={allVenues?.map((venue) => ({
                    label: venue.name,
                    value: venue.venue_id,
                  }))}
                  placeholder="Choose venues..."
                  selectedOptions={venues}
                  statusType={infoLoading ? 'loading' : infoError ? 'error' : 'finished'}
                  expandToViewport
                  keepOpen
                  virtualScroll
                  onChange={({ detail }) => setVenues([...detail.selectedOptions])}
                />
              </FormField>
            ) : null}
            {status ? (
              status === 'error' ? (
                <StatusIndicator type="error">Error</StatusIndicator>
              ) : (
                <StatusIndicator type="success">User invited!</StatusIndicator>
              )
            ) : loading ? (
              <StatusIndicator type="loading">Inviting...</StatusIndicator>
            ) : (
              <div />
            )}
          </SpaceBetween>
        </Form>
      </form>
    </Container>
  );
}
