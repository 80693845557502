import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { rangeStartEnd } from '../filtering/filter';

import axiosInstance from './axiosInstance';

export type PaginatedResponse<T> = {
  page: number;
  limit: number;
  total: number;
  pages: number;
  items: T[];
};

export async function getQueryResource<T>(
  route: string,
  query?: PropertyFilterQuery,
  page = 1,
  limit = 20,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
  rangeName?: string,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
): Promise<PaginatedResponse<T>> {
  const operators = query ? query.tokens.map((token) => token.operator) : [];
  const propertyKeys = query ? query.tokens.map((token) => token.propertyKey as string) : [];
  const values = query ? query.tokens.map((token) => token.value as string) : [];

  if (range) {
    const { start, end } = rangeStartEnd(range, now);
    const startIso = start.toISOString();
    const endIso = end.toISOString();
    let rangePropertyKey = 'created_at';

    if (rangeName) {
      rangePropertyKey = rangeName;
    }
    propertyKeys.push(rangePropertyKey);
    operators.push('>=');
    values.push(startIso);
    propertyKeys.push(rangePropertyKey);
    operators.push('<=');
    values.push(endIso);
  }

  const op = query ? (query.operation === 'or' ? 'or' : undefined) : undefined;
  const response = await axiosInstance.get(route, {
    params: {
      op,
      o: operators,
      k: propertyKeys,
      v: values,
      page,
      limit,
      sort_by: sortingField,
      sort_direction: sortDirection,
    },
    // Get rid of [] in query params
    // https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });

  return response.data as PaginatedResponse<T>;
}

export async function getReportResource<T>(
  route: string,
  query?: PropertyFilterQuery,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
  rangeName?: string,
) {
  const operators = query ? query.tokens.map((token) => token.operator) : [];
  const propertyKeys = query ? query.tokens.map((token) => token.propertyKey as string) : [];
  const values = query ? query.tokens.map((token) => token.value as string) : [];

  if (range) {
    const { start, end } = rangeStartEnd(range, now);
    const startIso = start.toISOString();
    const endIso = end.toISOString();
    let rangePropertyKey = 'created_at';

    if (rangeName) {
      rangePropertyKey = rangeName;
    }
    propertyKeys.push(rangePropertyKey);
    operators.push('>=');
    values.push(startIso);
    propertyKeys.push(rangePropertyKey);
    operators.push('<=');
    values.push(endIso);
  }

  const op = query ? (query.operation === 'or' ? 'or' : undefined) : undefined;
  const response = await axiosInstance.get(route, {
    params: {
      op,
      o: operators,
      k: propertyKeys,
      v: values,
    },
    // Get rid of [] in query params
    // https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });

  return response.data as T;
}

export async function getAllQueryResource<T>(
  route: string,
  query?: PropertyFilterQuery,
  limit = 20,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
  rangeName?: string,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
) {
  let page = 1;
  let pages = 1;
  const items: T[] = [];

  while (page <= pages) {
    const response = await getQueryResource<T>(
      route,
      query,
      page,
      limit,
      range,
      now,
      rangeName,
      sortingField,
      sortDirection,
    );
    items.push(...response.items);
    page++;
    pages = response.pages;
  }

  return items;
}
