import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { Device, DeviceWithVenue, Order, OrderMeta, Venue, VenueWithDevices } from '../types';

import { getAllQueryResource, getQueryResource } from './query';
import { getMetaQueryResource } from './meta';
import axiosInstance from './axiosInstance';

export async function getVenues(
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getQueryResource<VenueWithDevices>(
    '/venues',
    query,
    page,
    limit,
    undefined,
    now,
    undefined,
    sortingField,
    sortDirection,
  );
}

export async function getAllVenues(
  query?: PropertyFilterQuery,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getAllQueryResource<VenueWithDevices>(
    '/venues',
    query,
    20,
    undefined,
    now,
    undefined,
    sortingField,
    sortDirection,
  );
}

export async function getVenue(uuid: string) {
  const response = await axiosInstance.get(`/venues/${uuid}`);

  return response.data as VenueWithDevices;
}

export async function getVenuesMeta() {
  return await getMetaQueryResource('/venues/meta');
}

export async function getVenueOrders(
  uuid: string,
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getQueryResource<Order>(
    `/venues/${uuid}/orders`,
    query,
    page,
    limit,
    range,
    now,
    'rent_time',
    sortingField,
    sortDirection,
  );
}

export async function getVenueOrdersMeta(uuid: string) {
  return await getMetaQueryResource(`/venues/${uuid}/orders/meta`);
}

export async function getVenueDevices(
  uuid: string,
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  sortingField?: string,
  sortDirection?: 'asc' | 'desc',
  now = new Date(),
) {
  return await getQueryResource<DeviceWithVenue>(
    `/venues/${uuid}/devices`,
    query,
    page,
    limit,
    undefined,
    now,
    undefined,
    sortingField,
    sortDirection,
  );
}

export async function getVenueDevicesMeta(uuid: string) {
  return await getMetaQueryResource(`/venues/${uuid}/devices/meta`);
}
