import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  SpaceBetween,
  Button,
  Container,
  DatePicker,
  Input,
  FormField,
  Alert,
  Box,
  Select,
  Spinner,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useLocalFilterState, useLocalSortState } from '../common/filtering/filter';
import { ZappyHeader } from '../common/header';
import { OrderTable } from '../components/OrderTable';
import { ErrorMessage } from '../components/ErrorMessage';
import { ViewLayout } from '../components/ViewLayout';
import { getDashboardReport } from '../common/api/reports';

import { DashboardFilter } from './filter';
import { DashboardMetrics } from './metrics';

function Content() {
  const [now, setNow] = useState(new Date());
  const tzoffset = now.getTimezoneOffset() * 60000;
  const localISOTime = new Date(now.getTime() - tzoffset).toISOString().slice(0, -1);
  const [date, setDate] = useState(localISOTime.split('T')[0]);
  const [days, setDays] = useState(30);
  const endDate = new Date(date + 'T23:59:59');
  const startDate = new Date(endDate.getTime() - days * 24 * 60 * 60 * 1000);

  const orderSort = useLocalSortState('rent_time', 'desc');
  const { query, setQuery } = useLocalFilterState();

  const [range, setRange] = useState<DateRangePickerProps.Value | null>({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    type: 'absolute',
  });

  const { data, isPending, isFetching, error } = useQuery({
    queryKey: ['dashboard', endDate, days, now],
    queryFn: () => getDashboardReport(endDate, days),
    staleTime: 3_600_000, // 1 hour
  });

  const loading = (isPending || isFetching) && !error;

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="m">
        <Container>
          <SpaceBetween direction="vertical" size="xs">
            <DashboardFilter
              date={date}
              days={days}
              loading={loading}
              refresh={() => {
                setNow(new Date());
              }}
              setDate={setDate}
              setDays={setDays}
            />
            <DashboardMetrics days={days} error={error} loading={loading} report={data} />
          </SpaceBetween>
        </Container>
        {error ? (
          <ErrorMessage
            error={error}
            loading={loading}
            retry={() => {
              setNow(new Date());
            }}
          />
        ) : undefined}
      </SpaceBetween>
      <OrderTable
        {...orderSort}
        description={`Zappy orders within the last ${days} days`}
        now={endDate}
        query={query}
        range={range}
        setNow={() => {
          console.log('setNow not implemented');
        }}
        setQuery={setQuery}
        setRange={setRange}
        variant="container"
        hideActions
        hideDateRange
      />
    </SpaceBetween>
  );
}

export default function DashboardPage() {
  return (
    <ViewLayout
      breadcrumbs={[{ text: 'Overview', href: '' }]}
      contentType="dashboard"
      header={<ZappyHeader title="Dashboard" />}
      headerVariant="high-contrast"
    >
      <Content />
    </ViewLayout>
  );
}
