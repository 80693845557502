import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { Device, DeviceWithVenue, Order, OrderMeta, UserLogin, UserResponse } from '../types';

import axiosInstance from './axiosInstance';

export async function loginRequest(info: UserLogin) {
  const response = await axiosInstance.post('/auth/login', info);

  return response.data as UserResponse;
}
