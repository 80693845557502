export type User = {
  id: string;
  email: string;
};

export type UserSignup = {
  email?: string;
  password: string;
  code?: string;
};
export type UserUpdate = {
  password?: string;
};

export type UserPasswordResetChange = {
  email: string;
  code: string;
  password: string;
};

export type UserPasswordReset = {
  email: string;
};

export type UserInvite = {
  email: string;
  roles?: string[];
  venues?: string[];
};

export type UserInviteResponse = {
  id: string;
  email: string;
  code: string;
  // roles: string[];
  redeemed: boolean;
  sent_at: string;
  redeemed_at?: string;
};

export type SignupStatus = 'pending' | 'approved' | 'unknown';

export type SignupResponse = {
  status: SignupStatus;
};

export type UserLogin = {
  email: string;
  password: string;
};

export type EmailRequest = {
  email: string;
};

export type UserResponse = {
  id: string;
  token: string; // JWT token with User data
  refresh_token: string;
};

export type TokenRefreshResponse = {
  id: string;
  token: string;
};

export type UserListResponse = {
  id: string;
  uuid: string;
  email: string;
  created_at: string;
  roles: string[];
};

export type Role = 'basic' | 'internal' | 'venue' | 'admin';
export type RoleStatus = {
  name: Role;
  enabled: boolean;
  display: string;
};

export const ROLE_INFO: RoleStatus[] = [
  { name: 'basic', enabled: true, display: 'Basic' },
  { name: 'internal', enabled: false, display: 'Internal' },
  { name: 'venue', enabled: false, display: 'Venue' },
  { name: 'admin', enabled: false, display: 'Admin' },
];
export type RoleUpdateRequest = {
  roles: Role[];
};

export type PublicUser = {
  id: string;
  uuid: string;
  email: string;
  created_at: string;
};

export type UserTokenInfo = {
  id: string;
  uuid: string;
  type: string;
  exp: number;
  email: string;
  roles: string[];
  permissions: string[];
};

export type AuthRole = {
  id: number;
  name: string;
  permissions?: string[];
};

export type AuthPermission = {
  id: number;
  name: string;
};

export type Vendor = {
  id: number;
  uuid: string;
  name: string;
};

export type Device = {
  id: number;
  uuid: string;
  vendor: Vendor;
  device_id: string;
  slots: number;
  active_slots: number;
  status: string;
  kiosk?: ZohoKiosk;
};

export type DeviceWithOrders = Device & {
  orders: Order[];
};

export type DeviceWithUptime = Device & {
  orders: Order[];
  online_hours: number;
};

export type DeviceWithUptimeStats = DeviceWithUptime & {
  rps: number;
  tps: number;
};

export type DeviceStats = Device & {
  orders: Order[];
  rps: number;
  tps: number;
};

export type ZohoKiosk = {
  crm_id: string;
  name: string;
  site_crm_id?: string;
  status?: string;
  deal_crm_id?: string;
  internal_id?: string;
  product_crm_id?: string;
  country_specification_1?: string;
  colour?: string;
  slots?: string;
  manufacturer?: string;
  sim_card?: string;
  sim_card_provider?: string;
  hardware_provider?: string;
  terminal_crm_id?: string;
  serial_number?: string;
  processing_company?: string;
  terminal_id_activated?: string;
  terminal_id_deactivated?: string;
  previous_site_crm_id?: string;
  warehouse?: string;
  expected_in_stock_date?: string;
  in_stock_date?: string;
  test_completed?: string;
  back_end_set_up?: string;
  mmo_software_added?: string;
  video_uploaded?: string;
  pos_test?: string;
  logo_installed?: string;
  position_description?: string;
  install_date?: string;
  uninstall_date?: string;
};

export type Venue = {
  id: number;
  uuid: string;
  name: string;
  type?: string;
  venue_id: string;
  latitude?: number;
  longitude?: number;
  address?: string;
  logo?: string;
  open_time?: string;
  description?: string;
  currency?: string;
  kiosk?: ZohoKiosk;
};

export type VenueWithOrders = {
  venue: string;
  orders: Order[];
  revenue: number;
  venueData: Venue;
};
export type VenueWithOrdersAndStats = VenueWithOrders & {
  rps: number;
  tps: number;
};

export type DeviceWithVenue = Device & {
  venue: Venue;
};

export type VenueDevice = {
  id: number;
  uuid: string;
  device_id: string;
  slots: number;
  active_slots: number;
  status: string;
};

export type ZohoDeal = {
  id: number;
  crm_id: string;
  deal_number: string;
  owner: string;
  pipeline?: string;
  deal_name?: string;
  stage?: string;
  country_of_operation?: string;
  deal_type?: string;
  number_of_pilot_locations?: number;
  create_site?: boolean;
  industry_type?: string;
  created_by?: string;
  type_of_placement_agreement?: string;
  revenue_share_to_venue?: number;
  sponsorship_amount_annual?: number;
  lease_amount_annual?: number;
  battery_replacement?: string;
  contract_type?: string;
  request_contract?: boolean;
  lead_source?: string;
  affiliate_crm_id?: string;
  affiliate_name?: string;
  accounting_contact?: string;
  accounting_email?: string;
  accounting_phone?: string;
  website?: string;
  receives_monthly_reporting?: string;
  receives_monthly_payout?: string;
};

// class ZohoSite(SQLModel):
//     crm_id: str = Field(max_length=200, unique=True, index=True)
//     name: Optional[str]
//     modified_time: Optional[datetime]
//     site_number: Optional[str]
//     created_time: Optional[datetime]
//     last_activity_time: Optional[datetime]
//     deal_number: Optional[str]
//     deal_crm_id: Optional[str]
//     fulfillment_crm_id: Optional[str]
//     address: Optional[str]
//     unit_number: Optional[str]
//     state_or_province: Optional[str]
//     city: Optional[str]
//     country: Optional[str]
//     post_code: Optional[str]
//     modified_by: Optional[str]
//     created_by: Optional[str]
//     owner: Optional[str]
//     set_up_completed_by: Optional[str]
//     back_end_set_up_complete: Optional[date]
//     site_contact_crm_id: Optional[str]

export type ZohoSite = {
  id: number;
  crm_id: string;
  name?: string;
  modified_time?: string;
  site_number?: string;
  created_time?: string;
  last_activity_time?: string;
  deal_number?: string;
  deal_crm_id?: string;
  fulfillment_crm_id?: string;
  address?: string;
  unit_number?: string;
  state_or_province?: string;
  city?: string;
  country?: string;
  post_code?: string;
  modified_by?: string;
  created_by?: string;
  owner?: string;
  set_up_completed_by?: string;
  back_end_set_up_complete?: string;
  site_contact_crm_id?: string;
  deal?: ZohoDeal;
};

export type VenueWithDevices = Venue & {
  devices: VenueDevice[];
  site?: ZohoSite;
};

export type Order = {
  id: number;
  uuid: string;
  source_id: string;
  order_number: string;
  iugu_order_number: string;
  order_type: string;
  slot: number;
  rent_time: string;
  payment_status: string;
  rent_status: string;
  returned_time?: string;
  settlement_amount: string;
  device: Device;
  from_venue: Venue;
  return_venue?: Venue;
};

export type DeviceHeartbeat = {
  id: number;
  heartbeat_id: string;
  start_time: string;
  end_time?: string;
};

export type DeviceUptime = {
  device_id: number;
  online_hours: number;
};

export type OrderReport = {
  sales: number;
  rentals: number;
  total_orders: number;
  sales_revenue: number;
  rental_revenue: number;
  total_revenue: number;
  total_devices: number;
  total_rideshare_devices: number;
  total_kiosk_devices: number;
  total_inactive_devices: number;
  device_avg_rps: number;
  device_avg_rev: number;
  arr: number;
  arr_sales_revenue: number;
  arr_rental_revenue: number;
  arr_sponsorship_revenue: number;
  avg_basket: number;
  total_rental_minutes: number;
  avg_rental_minutes: number;
  rideshare_signups: number;
  online_devices: number;
  offline_devices: number;
  total_slots: number;
  full_slots: number;
  empty_slots: number;
  sponsorship_revenue: number;
  apple_downloads: number;
};

export type DashboardReport = {
  report: OrderReport;
  prior_report: OrderReport;
  ytd_report: OrderReport;
};

export type OrderMeta = {
  total: number;
  rent_start?: string;
  rent_end?: string;
  devices?: string[];
  payment_statuses?: string[];
  rent_statuses?: string[];
  revenue_min?: number;
  revenue_max?: number;
  venues?: string[];
};

export type AccountSetupRequest = {
  email: string;
  code: string;
};

export type AccountSetupResponse = {
  setup_url?: string;
  login_url?: string;
  currency?: string;
};

export type CurrencyBreakdown = {
  currency: string;
  total_revenue: number;
  returned_revenue: number;
  non_returned_revenue: number;
};

export type DeviceReportRead = {
  device_id: string;
  device_uuid: string;
  slots: number;
  total_rentals: number;
  returned_rentals: number;
  non_returned_rentals: number;
  total_revenue: number;
  returned_revenue: number;
  non_returned_revenue: number;
  rps: number;
  tps: number;
};

export type VenueReportRead = {
  venue_name: string;
  venue_uuid: string;
  venue_currency: string;
  total_rentals: number;
  returned_rentals: number;
  non_returned_rentals: number;
  total_revenue: number;
  returned_revenue: number;
  non_returned_revenue: number;
  rps: number;
  tps: number;
  devices: DeviceReportRead[];
};

export type OrdersReportRead = {
  total_rentals: number;
  returned_rentals: number;
  non_returned_rentals: number;
  total_rental_minutes: number;
  avg_rps: number;
  avg_tps: number;
  currency_breakdowns: CurrencyBreakdown[];
  venues: VenueReportRead[];
};
