import { useState } from 'react';

import { ViewLayout } from '../components/ViewLayout';
import { useUrlPageState, useUrlSortState, useUrlFilterState } from '../common/filtering/filter';
import { VenueTable } from '../components/VenueTable';

function Content() {
  const [now, setNow] = useState(new Date());
  const queryProps = useUrlFilterState();
  const pageProps = useUrlPageState();
  const sortProps = useUrlSortState('name', 'asc');

  return <VenueTable {...queryProps} {...pageProps} {...sortProps} now={now} setNow={setNow} />;
}

export default function VenuesPage() {
  return (
    <ViewLayout breadcrumbs={[{ text: 'Venues', href: '' }]} contentType="table">
      <Content />
    </ViewLayout>
  );
}
