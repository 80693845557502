// description="Overview for the specified time range"
//                 report={reportQuery.data}
//                 title="Overview"

import { Box, ColumnLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import { Container } from '@cloudscape-design/components';
import { ReactNode } from 'react';

import { OrdersReportRead } from '../common/types';

export type OrdersReportProps = {
  description: string;
  report: OrdersReportRead;
  title: string;
  actions?: ReactNode;
};

export function OrdersReport(props: OrdersReportProps) {
  const { description, report, title } = props;

  const usd = report.currency_breakdowns.find((cb) => cb.currency === 'USD');
  const cad = report.currency_breakdowns.find((cb) => cb.currency === 'CAD');

  return (
    <SpaceBetween direction="vertical" size="m">
      <Header actions={props.actions} description={description}>
        {title ?? `Report`}
      </Header>
      <Container
        footer={
          <div>
            <Box variant="awsui-key-label">Rental Revenue</Box>
            <SpaceBetween direction="vertical" size="xxxs">
              {usd && (
                <Box variant="strong">
                  {usd.total_revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ??
                    '$0.00'}{' '}
                  USD
                </Box>
              )}
              {cad && (
                  <Box variant="strong">
                    {cad.total_revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ??
                      '$0.00'}{' '}
                    CAD
                  </Box>
                )
              }
            </SpaceBetween>
          </div>
        }
        variant="stacked"
      >
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Return Revenue</Box>
            <SpaceBetween direction="vertical" size="xxxs">
              {usd && (
                <Box variant="span">
                  {usd.returned_revenue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) ?? '$0.00'}{' '}
                  USD
                </Box>
              )}
              {cad && (
                <Box variant="span">
                  {cad.returned_revenue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) ?? '$0.00'}{' '}
                  CAD
                </Box>
              )}
            </SpaceBetween>
          </div>
          <div>
            <Box variant="awsui-key-label">Non-Return Revenue</Box>
            <SpaceBetween direction="vertical" size="xxxs">
              {usd && (
                <Box variant="span">
                  {usd.non_returned_revenue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) ?? '$0.00'}{' '}
                  USD
                </Box>
              )}
              {cad && (
                <Box variant="span">
                  {cad.non_returned_revenue.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) ?? '$0.00'}{' '}
                  CAD
                </Box>
              )}
            </SpaceBetween>
          </div>
        </ColumnLayout>
      </Container>
      <Container
        footer={
          <div>
            <Box variant="awsui-key-label">Total Rentals</Box>
            <Box variant="strong">{`${report.total_rentals.toLocaleString()}`}</Box>
          </div>
        }
        variant="stacked"
      >
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Returned Rentals</Box>
            <Box variant="span">{report.returned_rentals.toLocaleString()}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Non-Returned Rentals</Box>
            <Box variant="span">{report.non_returned_rentals.toLocaleString()}</Box>
          </div>
        </ColumnLayout>
      </Container>
      <Container
        footer={
          <ColumnLayout columns={2} variant="text-grid">
            <div>
              {/* TODO add hover info */}
              <Box variant="awsui-key-label">Average RPM</Box>
              <Box variant="span">${`${report.avg_rps.toFixed(2)}`}</Box>
            </div>
            <div>
              {/* TODO add hover info */}
              <Box variant="awsui-key-label">Average TPM</Box>
              <Box variant="span">{`${report.avg_tps.toFixed(1)}`}</Box>
            </div>
          </ColumnLayout>
        }
        variant="stacked"
      >
        <ColumnLayout columns={2} variant="text-grid">
          {/* <div>
            <Box variant="awsui-key-label">Online Hours</Box>
            <Box variant="span">{`${online.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })} hours`}</Box>
          </div> */}
          <div>
            <Box variant="awsui-key-label">Rental Minutes</Box>
            <Box variant="span">{`${report.total_rental_minutes.toLocaleString()} minutes`}</Box>
          </div>
          {/* <div>
            <Box variant="awsui-key-label">Active Devices</Box>
            <Box variant="span">{`${activeDevices.length.toLocaleString()}`}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Active Slots</Box>
            <Box variant="span">{`${activeSlots.toLocaleString()}`}</Box>
          </div> */}
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  );
}
