import { useMemo } from 'react';

import { PercentStatus } from '../common/percent-status';
import { VenueWithDevices } from '../common/types';

export type VenueSlotStatusProps = {
  venue: VenueWithDevices;
};

export function VenueSlotStatus(props: VenueSlotStatusProps) {
  const { venue } = props;
  const [total_slots, full_slots] = useMemo(() => {
    const unknown = venue.devices.filter((device) => device.status === 'Unknown').length;
    const total_slots = venue.devices.map((device) => device.slots).reduce((a, b) => a + b, 0);
    const empty_slots = venue.devices
      .map((device) => device.active_slots)
      .reduce((a, b) => a + b, 0);
    let full_slots: number | undefined = total_slots - empty_slots;

    if (unknown > 0) {
      full_slots = undefined;
    }

    return [total_slots, full_slots];
  }, [venue]);

  return <PercentStatus max_percent={50} min_percent={10} total={total_slots} value={full_slots} />;
}
