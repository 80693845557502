import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  SpaceBetween,
  Button,
} from '@cloudscape-design/components';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { VenueTable } from '../common/old/venue';
import { PropertyDateFilter } from '../common/filtering/filtering';
import { ViewLayout } from '../components/ViewLayout';
import { ZappyHeader } from '../common/header';
import { getOrderReport, getOrdersMeta } from '../common/api/orders';
import { useLocalSortState } from '../common/filtering/filter';
import { useLocalRangeState } from '../common/filtering/filter';
import { useLocalFilterState } from '../common/filtering/filter';
import { OrderTable } from '../components/OrderTable';
import { LoadingOrError } from '../components/Loading';
import { OrdersReport } from '../components/OrdersReport';
import { VenueOrdersTable } from '../components/VenueOrdersTable';

function Content() {
  const [now, setNow] = useState(new Date());
  const { query, setQuery } = useLocalFilterState();
  const { range, setRange } = useLocalRangeState(true, 'last-1-month');
  const orderSort = useLocalSortState('rent_time', 'desc');
  const past = true;

  const metaQuery = useQuery({
    queryKey: ['orders', 'meta', now],
    queryFn: getOrdersMeta,
    staleTime: 3_600_000, // 1 hour
  });

  const { options, properties } = useMemo(() => {
    if (metaQuery.data) {
      return {
        options: metaQuery.data.filteringOptions,
        properties: metaQuery.data.filteringProperties,
      };
    }

    return {
      options: [],
      properties: [],
    };
  }, [metaQuery.data]);

  const refresh = () => {
    setNow(new Date());
  };

  // wait for the meta query to finish before proceeding
  const reportQuery = useQuery({
    queryKey: ['orders', query, range, now],
    enabled: metaQuery.isSuccess,
    queryFn: async () => {
      const orders = await getOrderReport(query, range, now);

      return orders;
    },
    staleTime: 300_000, // 5 minutes
  });

  const loading =
    (reportQuery.isPending ||
      reportQuery.isFetching ||
      metaQuery.isPending ||
      metaQuery.isFetching) &&
    !reportQuery.error &&
    !metaQuery.error;

  const error = reportQuery.error || metaQuery.error;

  const itemFilter = (
    <PropertyDateFilter
      filteringProperties={properties}
      loading={loading}
      past={past}
      propertyFilteringOptions={options}
      query={query}
      range={range ?? null}
      setQuery={(q) => {
        setQuery?.(q);
      }}
      setRange={(r) => {
        setRange?.(r);
      }}
    />
  );

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, s: 5 } },
        { colspan: { default: 12, s: 7 } },
        { colspan: { default: 12 } },
      ]}
    >
      <SpaceBetween direction="vertical" size="s">
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <Button
                    disabled={loading}
                    iconName="refresh"
                    variant="normal"
                    onClick={refresh}
                  />
                </SpaceBetween>
              }
            >
              Report Settings
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="s">
            {itemFilter}
            {reportQuery.data && (
              <OrdersReport
                description="Overview for the specified time range"
                report={reportQuery.data}
                title="Overview"
              />
            )}
          </SpaceBetween>
        </Container>
        {(loading || error) && <LoadingOrError error={error} loading={loading} retry={refresh} />}
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="s">
        <VenueOrdersTable
          description="Venue metrics within the specified time range"
          error={error}
          loading={loading}
          pageSize={15}
          refresh={refresh}
          reports={reportQuery.data?.venues ?? []}
          title="Venues"
        />
      </SpaceBetween>
      <OrderTable
        {...orderSort}
        description="Zappy orders within the specified time range"
        now={now}
        query={query}
        range={range}
        setNow={setNow}
        variant="container"
        hideActions
        hideFilter
      />
    </Grid>
  );
}

export default function ReportPage() {
  return (
    <ViewLayout
      breadcrumbs={[{ text: 'Reporting', href: '' }]}
      contentType="dashboard"
      header={<ZappyHeader title="Reporting" />}
      headerVariant="high-contrast"
    >
      <Content />
    </ViewLayout>
  );
}
